import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Consumer } from 'store/createContext';
import { motion, useCycle } from 'framer-motion';
import { Container, NavContainer} from './header.css';
import Logo from './logo';
import Nav from './nav';
import MenuButton from './menu-button';


const sidebar = {
  open: {
    scale: 500,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 50
    }
  },
  closed: {
    scale: 1,
    transition: {
      delay: 0.1,
      type: "spring",
      stiffness: 200,
      damping: 25
    }
  }
};


const Header = ({ title, showTitle }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [isProject, setIsProject] = useState(false);
  const [projectId, setProjectId] = useState('');
  const nodeNav = useRef();
  const nodeCloseBtn = useRef();

  
  if (typeof window !== 'undefined') {
    useEffect(() => {
      if (/project/.test(location.pathname) ) {
        setIsProject(true);
        setProjectId(location.pathname.split("project/")[1]);
      } else {
        setIsProject(false);
        setProjectId('');
      }

      if (isOpen) { 
        document.documentElement.classList.add("no-scroll");
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.documentElement.classList.remove("no-scroll");
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen, location.pathname]);
  } else {
    useEffect(() => {
      if (isOpen) { 
        document.documentElement.classList.add("no-scroll");
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.documentElement.classList.remove("no-scroll");
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isOpen]);
  }

  const handleClick = () => { 
    if (isOpen) { toggleOpen() } else return;
  }

  const handleClickOutside = e => { 
    if (nodeCloseBtn.current.contains(e.target)) { return; }
    if (nodeNav.current.contains(e.target)) { return; }

    if (isOpen) { 
      document.removeEventListener("mousedown", handleClickOutside); 
      toggleOpen();
    } else return;
  }
  
  return (
    <Consumer>
    {({ varAccent }) => (
      <motion.div 
        id="top"
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <Container>

          <Link to={isProject ? "/#"+projectId : "/"} onClick={handleClick} className='button'>
            <Logo className="logo" isProject={isProject} varAccent={varAccent}/>
          </Link>
        
          <div className={(showTitle && !isProject) ? "title" : "title hide"}>
            <div className="title-text">{title}</div>
          </div>
  
          <motion.div
            initial={false}
            animate={isOpen ? "open" : "closed"}
            whileHover={isOpen ? "hoverclosed" : "hover"}
            ref={nodeCloseBtn}
          >
            <MenuButton toggle={() => toggleOpen()} id='menutoggle' isOpen={isOpen} varAccent={varAccent} />
          </motion.div>
          
        </Container>

        <NavContainer ref={nodeNav} varAccent={varAccent}>

          <motion.nav
            initial={false}
            animate={isOpen ? "open" : "closed"}
            className={isOpen ? "open" : ""}
            whileHover="hover"
            whileTap="press"
          >
            <motion.div 
              className="nav-bg" 
              variants={sidebar}
            />
            <Nav toggle={() => toggleOpen()} />
            
          </motion.nav>

        </NavContainer>
      
      </motion.div>
    )}
    </Consumer>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
