import { createGlobalStyle } from 'styled-components';
import { accent, hover } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }
  
  html, body {
    overflow-x: hidden;
  }

  body, input, textarea {
    font-family: 'Karla', sans-serif;
    font-size: 1.8rem;
    line-height: 1.66;
    letter-spacing: -0.05rem;
    color: ${accent};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	input, select, textarea, button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
    appearance: none;
    outline: 0;
  }
  
  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
  }

  a {
    display: inline-block;
    position: relative;
    z-index: 1;
    font-weight: bold;
    color: inherit;
    text-decoration: none;
    transition: color 0.2s linear;
  }
  a::after {
      position: absolute;
      z-index: -1;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 100%;
      height: 2px;
      background-color: ${accent};
      border-radius: 4px;
      transition: all 0.2s ease 0s;
  }
  a:hover {
    color: white;
  }
  a:hover::after {
    height: 80%;
    width: 105%;
  }

  a.button::after {
    content: none;
  }

  video {
    max-width: 100%;
  }

  h1 {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 1.16;
    letter-spacing: -0.1rem;
  }
  h2 {
    font-weight: bold;
    font-size: 3.6rem;
    line-height: 1.16;
    letter-spacing: -0.1rem;
  }
  h3 {
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 1.22;
    letter-spacing: -0.08rem;
  }
  h4 {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: normal;
  }

  p {
    margin-bottom: 0rem;
  }

  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }

  small {
    font-size: 1.2rem;
    line-height: 1.2;
    letter-spacing: normal;
  }

  .centered {
    text-aligned: center;
  }
  .left {
    text-aligned: left;
  }
  .right {
    text-aligned: right;
  }

  ${MEDIA.TABLET`
    .no-scroll {
      overflow: hidden;

      .title {
        opacity: 1 !important;
      }
    }
  `};


`;