import React from 'react';
import { Link } from 'gatsby';
import IconLinkedin from 'images/icons/icon-linkedin.svg';
import IconTwitter from 'images/icons/icon-twitter.svg';
import IconInstagram from 'images/icons/icon-instagram.svg';
import { motion } from "framer-motion";


const variantsUL = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

const variantsLI = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { 
        type: "spring",
        stiffness: 300,
        damping: 20
      }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: { duration: 0.2 }
  },
};

const Nav = ({ toggle }) => (
  <div className="nav-container">
    <div className="list-container">
      <motion.ul variants={variantsUL} className="links">
        <motion.li key="menuLink_1" variants={variantsLI}>
          <Link to="/" activeClassName="active" onClick={toggle}>Home</Link>
        </motion.li>
        <motion.li key="menuLink_2" variants={variantsLI}>
          <Link to="/about" activeClassName="active" partiallyActive={true} onClick={toggle}>About</Link>
        </motion.li>
        <motion.li key="menuLink_3" variants={variantsLI}>
          <Link to="/contact" activeClassName="active" partiallyActive={true} onClick={toggle}>Contact</Link>
        </motion.li>
      </motion.ul>
      <motion.ul variants={variantsUL} className="social" >
        <motion.li key="menuSocial_1" variants={variantsLI} >
          <a href="https://www.linkedin.com/in/maxlacoste/" target="_blank" rel="nofollow noopener noreferrer"><IconLinkedin /></a>
        </motion.li>
        <motion.li key="menuSocial_2" variants={variantsLI} >
          <a href="https://twitter.com/nolazypixel" target="_blank" rel="nofollow noopener noreferrer"><IconTwitter /></a>
        </motion.li>
        <motion.li key="menuSocial_3" variants={variantsLI} >
          <a href="https://www.instagram.com/ethereal_tones/" target="_blank"rel="nofollow noopener noreferrer"><IconInstagram /></a>
        </motion.li>
      </motion.ul>
    </div>
  </div>
);


export default Nav;