import React from 'react';
import Layout from 'components/layout';
import PropTypes from 'prop-types';

const wrapPageElementWithTransition = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

wrapPageElementWithTransition.propTypes = {
  element: PropTypes.any,
  props: PropTypes.any,
};

export default wrapPageElementWithTransition;
