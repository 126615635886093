import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { MINH, MINLH, MINXLH }  from 'constants/breakpoints';

export const Container = styled.header`
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 15;
  width: 100vw;
  max-width: 100%;
  height: 68px;
  margin-top: 40px;
  padding: 0 40px;
  box-sizing: border-box;

  a {
    pointer-events: auto;
    height: 100%;
  }

  svg {
    width: auto;
    height: 100%;
  }

  button {
    position: relative;
    pointer-events: auto;
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    width: 68px;
    height: 68px;
    background: transparent;
    padding: 18px;
  }

  .title {
    pointer-events: none;
    flex-grow: 2;
    max-width: 100%;
    margin: 0 16px;
    text-align: left;
    transition: opacity 0.2s linear;

    .title-text {
      display: block;
      font-weight: bold;
      font-size: 1.8rem;
      letter-spacing: 0.25rem;
      line-height: 1.2;
      text-transform: uppercase;
      max-width: 100px;
    }
  }
  
  .hide {
    opacity: 0;
  }



  ${MEDIA.TABLET`
    height: 50px;
    margin-top: 24px;
    padding: 0 24px;

    .title {
      text-align: center;
      color: white;

      .title-text {
        font-size: 1.5rem;
        max-width: 100%;
        letter-spacing: 0.15rem;
      }
    }

    button {
      width: 50px;
      height: 50px;
      padding: 12px;
    }
  `};
`;



export const NavContainer = styled.div`
  pointer-events: none;

  .open {
    pointer-events: auto;
    box-shadow: 0px 0px 32px -24px rgba(0,0,0,1);
    transition: box-shadow 1.5s;
  }

  nav {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    min-height: 220px;
    overflow: hidden;
    box-shadow: 0px 0px 32px -24px rgba(0,0,0,0);
    transition: box-shadow 0.5s;

    .nav-bg {
      position: absolute;
      top: 70px;
      right: 70px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${ props => props.varAccent };
      transition: background-color 1s;
    }
    
    .nav-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .list-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  
    ul.links {
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;
      justify-content: center;
      height: 50%;
      
      @media (max-height: ${MINH}px) {
        flex-direction: row;
        width: 100%;
      }
  
      li {
        font-weight: bold;
        font-size: 1.8rem;
        letter-spacing: 0.1rem;
      }

      li:not(:last-child):after {
        content: "•";
        color: white;
        display: block;
        text-align: center;
        margin: 32px 0;

        @media (max-height: ${MINXLH}px) {
          margin: 16px 0;
        }
        @media (max-height: ${MINLH}px) {
          margin: 4px 0;
        }
        @media (max-height: ${MINH}px) {
          display: inline-block;
          text-align: center;
          margin: 0 12px;
        }
      }
    }

    a {
      color: white;
    }
    a::after {
      width: 120%;
      bottom: 50%;
      background-color: ${ props => props.varAccent };
    }
    a:hover {
      color: ${ props => props.varAccent };
    }
    a:hover::after {
      background-color: white;
      height: 100%;
      bottom: 0;
    }
    a.active {
      pointer-events: none;
    }
    a.active::after {
      background-color: white;
      bottom: 2px;
      width: 100%;
    }


    ul.social {
      position: absolute;
      bottom 24px;
      display: flex;
      justify-content: space-around;
      list-style: none;
      height: 50px;
      padding: 0 10%;
  
      li {
        padding: 0;
        margin: 0 16px;
      }
  
      a {
        height: 100%;
      }
      a::after {
        content: none;
      }
  
      svg {
        width: auto;
        height: 100%;
        transition: all 200ms ease;

        circle {
          fill: rgba(255,255,255,0);
          transition: all 200ms ease;
        }
        :hover {
          transform: scale(1.2);
        }
        :hover circle {
          fill: rgba(255,255,255,0.1);
          stroke: ${ props => props.varAccent };
        }
      }
      
    }

    ${MEDIA.TABLET`
      width: 100%;

      .nav-bg {
        top: 24px;
        right: 24px;
        width: 50px;
        height: 50px;
      }
    `};
  }

  
`;