import React, { useState } from 'react';
import styled from 'styled-components';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';
import PrimaryButton from 'components/primary-button';


const Container = styled.div`
  h1 {
    text-align: center;
    padding-top: 128px;
  }

  p {
    text-align: center;
    margin: 32px 0 8px;
  }

  input {
    display: block;
    background-color: #f7f7f7;
    border-radius: 22px;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    width: 80%;
    max-width: 300px;
    padding: 9px 24px;
    margin: 0 auto;
    text-decoration: none;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.63rem;
    letter-spacing: normal;
  }
`;

const PasswordProtect = () => {
  const [password, setPassword] = useState('');

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <Container>
      
        <h1>This page is private</h1>
        <p>Please enter the password:</p>

        <form onSubmit={onSubmit}>
          <input
            name="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <div style={{ width: '180px', margin: '32px auto 80px' }}>
          <PrimaryButton type="submit">
            Enter
          </PrimaryButton>
          </div>
        </form>
      
    </Container>
  );
};

export default PasswordProtect;