import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';


const handleExit = () => {
  if (typeof window !== "undefined") {
    if ((location.hash != "") && (document.querySelector(`${cleanLocationHash(location.hash)}`) !== null)) {
      window.scrollTo({top: document.querySelector(`${cleanLocationHash(location.hash)}`).offsetTop});
    } else{
      window.scrollTo(0,0);
    }
  }
}

const cleanLocationHash = (loc) => {
    if (loc.substring(loc.length-1) == "/") {
      loc = loc.substring(0, loc.length-1);
    }
    return loc;
}


const Transition = ({ children, location }) => {
  const variants = {
    initial: {
      opacity: 0,
      //x: "100vw",
    },
    enter: {
      opacity: 1,
      //x: 0,
      transition: {
        duration: 0.4,
        delay: 0.4,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      //x: "-100vw",
      transition: { duration: 0.4 },
    },
  }

  
 

  return (
   
    <AnimatePresence onExitComplete={ handleExit }>
      <motion.div
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default Transition