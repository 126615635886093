import React from 'react';
import PropTypes from 'prop-types';
import { motion } from "framer-motion";
import styled from 'styled-components';
import { accent } from 'constants/theme';


const Button = styled(motion.button)`
  position: relative;
  z-index:0;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: 0.1rem;
  color:#fff;
  padding: 17px 32px;
  border: none;
  background: none;
  min-width: 180px;
`;

const ButtonBg = styled(motion.div)`
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${ props => (props.buttoncolor ? props.buttoncolor : accent) };
  border-radius: 28px;
`;

const bgMotion = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 5,
      stiffness: 500,
    }
  },
  press: {
    scale: 0.9,
    transition: {
      duration: 0.15,
      type: "tween",
      ease: "easeOut"
    }
  }
};


const PrimaryButton = ({ children, buttontype, buttoncolor }) => {
  return (
    <Button
      type={buttontype} 
      whileHover="hover"
      whileTap="press"
    >
      {children}
      <ButtonBg 
        buttoncolor={buttoncolor}
        variants={bgMotion}
      />
    </Button>
  )};

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttontype: PropTypes.string,
  buttoncolor: PropTypes.string,
};

export default PrimaryButton;