import React from 'react';
import { motion } from "framer-motion";
import styled from 'styled-components';


const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="white"
    strokeLinecap="round"
    {...props}
    transition= {{
      type: "spring",
      stiffness: 800,
      damping: 25
    }}
  />
);

const ButtonBg = styled(motion.div)`
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${ props => props.varAccent };
  border-radius: 50%;
  transition: background-color 1s;
`;
const ButtonBgOpen = styled(motion.div)`
  position: absolute;
  z-index:-2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 50%;
`;


const MenuButton = ({ toggle, isOpen, varAccent }) => {
  
  return (
  <button onClick={toggle} >
    
    <svg width="32" height="32" viewBox="0 0 32 32" aria-labelledby="menuButtonID">
      <title id="menuButtonID">{!isOpen ? "Open the menu" : "Close the menu"}</title>
      <Path
        variants={{
          closed: { d: "M 6 12 L 26 12" },
          open: { d: "M 8 24 L 24 8" },
          hover: { d: "M 6 9 L 26 9" },
          hoverclosed: { d: "M 6 26 L 26 6" },
        }}
      />
      <Path
        variants={{
          closed: { d: "M 16 16 L 16 16", opacity: 0 },
          open: { opacity: 0 },
          hover: { d: "M 9 16 L 23 16", opacity: 1 }
        }}
      />
      <Path
        variants={{
          closed: { d: "M 6 20 L 26 20" },
          open: { d: "M 8 8 L 24 24" },
          hover: { d: "M 6 23 L 26 23" },
          hoverclosed: { d: "M 6 6 L 26 26" },
        }}
      />
    </svg>
    <ButtonBgOpen 
      variants={{
        closed: {
          opacity: 0,
        },
        hoverclosed: {
          opacity: 0.1,
        },
      }}
    />
    <ButtonBg 
      varAccent = { varAccent }
      variants={{
        closed: {
          opacity: 1,
        },
        open: {
          opacity: 0,
        },
        hover: {
          scale: 1.1,
          transition: {
            duration: 0.2,
            type: "spring",
            damping: 5,
            stiffness: 500,
          }
        },
        
      }}
    />
  </button>
  )
};

export default MenuButton;