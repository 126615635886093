import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Icon from 'images/logo-icon.svg';


const Button = styled(motion.div)`
  position: relative;
  width: 68px;
  height: 68px;

  ${MEDIA.TABLET`
    width: 50px;
    height: 50px;
  `};
`;

const ButtonBg = styled(motion.div)`
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${ props => props.varAccent };
  border-radius: 50%;
  transition: background-color 1s;
`;

const bgMotion = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 5,
      stiffness: 500,
    }
  },
  press: {
    scale: 0.9,
    transition: {
      duration: 0.15,
      type: "tween",
      ease: "easeOut"
    }
  }
};

const Path = props => (
  <motion.path
    fill="transparent"
    stroke="white"
    strokeWidth="2"
    strokeLinecap="round"
    {...props}
    transition= {{
      type: "spring",
      stiffness: 800,
      damping: 25
    }}
  />
);


const Logo = ({ isProject=false, varAccent }) => {
  return (
    <AnimatePresence initial={false}>
    <Button
      whileHover="hover"
      whileTap="press"
    >
      <motion.svg width="68" height="68" viewBox="0 0 68 68" className="logo" aria-labelledby="logoID">
        <title id="logoID">{!isProject ? "Max Lacoste's logo" : "Back to Homepage"}</title>
    
        { 
          !isProject && <motion.g
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100  }}
            
          >
            <Icon />
            <motion.use 
              href={ "#lids" }
              variants={{
                hover: { y: -2 , scaleY: 0.9}
              }}
            />
            <motion.use 
              href={ "#pupil" }
              variants={{
                hover: { rotate: 110 }
              }}
            />
            <motion.use 
              href={ "#eye" }
              variants={{
                hover: { scaleY: 1.1 }
              }}
            />
            <motion.use 
              href={ "#bottom-dot" }
              variants={{
                hover: { y: 3 }
              }}
            />
            <motion.use 
              href={ "#bottom-dot" }
              variants={{
                hover: { y: 9 }
              }}
            />
          </motion.g>
        }
        {
          isProject && <motion.g
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0  }}
            exit={{ opacity: 0, x: -100 }}
          >
            <Path
              d= "M 32 27 L 25 34 L 32 41"
              variants={{
                hover: { d: "M 28 27 L 21 34 L 28 41" }
              }}
            />
            <Path
              d= "M 31 34 L 43 34"
              variants={{
                hover: { d: "M 27 34 L 43 34" }
              }}
            />
          </motion.g>
        }
        
      </motion.svg>

      <ButtonBg 
        variants={bgMotion}
        varAccent={varAccent}
      />
    </Button>
    </AnimatePresence>
  )
};

export default Logo;