import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import styled from 'styled-components';
import { Consumer } from 'store/createContext';
import MEDIA from 'helpers/mediaTemplates';
import { motion } from 'framer-motion';


const Button = styled(motion.button)`
  position: fixed;
  z-index: 6;
  bottom: 40px;
  right: 40px;
  width: 68px;
  height: 68px;
  background: transparent;
  padding: 18px;

  svg {
    width: auto;
    height: 100%;
  }

  ${MEDIA.TABLET`
    bottom: 24px;
    right: 24px;
    width: 50px;
    height: 50px;
    padding: 12px;
  `};
`;

const ButtonBg = styled(motion.div)`
  position: absolute;
  z-index:-1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${ props => props.varAccent };
  border-radius: 50%;
  transition: background-color 1s;
`;

const Path = props => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="white"
    strokeLinecap="round"
    {...props}
    transition= {{
      type: "spring",
      stiffness: 800,
      damping: 25
    }}
  />
);

const btnMotion = {
  hide: {
    y: 200,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 25,
      stiffness: 800,
    }
  }
};

const bgMotion = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.2,
      type: "spring",
      damping: 5,
      stiffness: 500,
    }
  },
  press: {
    scale: 0.9,
    transition: {
      duration: 0.15,
      type: "tween",
      ease: "easeOut"
    }
  }
};


const ScrollUp = ({ showBtn }) => {

  const handleClick = () => { 
    scrollTo('#top');
  }
  
  return (
    <Consumer>
    {({ varAccent }) => (
      <Button
        initial="hide"
        animate={(showBtn ? "show" : "hide")}
        whileHover="hover"
        whileTap="press"
        variants={btnMotion}
        onClick={handleClick}
      >
        <svg width="32" height="32" viewBox="0 0 32 32" aria-labelledby="scrollUpButtonID">
          <title id="scrollUpButtonID">Scroll back up to the top of the page</title>
          <Path
            variants={{
              hide: { d: "M 9 14 L 16 7 L 23 14" },
              hover: { d: "M 9 10 L 16 3 L 23 10" }
            }}
          />
          <Path
            variants={{
              hide: { d: "M 16 13 L 16 25" },
              hover: { d: "M 16 9 L 16 25" }
            }}
          />
        </svg>
        <ButtonBg 
          variants={bgMotion}
          varAccent={varAccent}
        />
      </Button>
    )}
    </Consumer>
  )
};

export default ScrollUp;