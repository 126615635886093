import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './createContext';
import { accent } from 'constants/theme';


class AppProvider extends Component {
  state = {
    varAccent: accent,
    updateAccent: (newAccent) => this.setState({ varAccent: newAccent }),
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
