import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useScroll } from 'framer-motion';
import GlobalStyle from 'global.css.js';
import Transition from './transition';
import ScrollUp from './scroll-up';
import Border from './border';
import Head from 'components/head';
import Header from 'components/header';



const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteTitleShort
        }
      }
    }
  `)

  //const [borderColor, setBorderColor] = useState('#222');
  const [showTitle, setShowTitle] = useState(true);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { scrollYProgress } = useScroll();  
  

  useEffect(() => {
    scrollYProgress.on("change", e => {
      if (e > 0.02) { 
        setShowTitle(false);  
      } else {
        setShowTitle(true); 
      }
      if (e > 0.1) { 
        setShowTopBtn(true); 
      } else {
        setShowTopBtn(false); 
      }
    })
  }, []);

  

  return (
    <>
      <GlobalStyle />
      <Head />
      <Header title={data.site.siteMetadata.siteTitleShort} showTitle={showTitle} />
      <ScrollUp showBtn={showTopBtn} />
      <Border />
      <Transition {...props} >
        <main>{props.children}</main>
      </Transition>
    </>
  )
}

export default Layout