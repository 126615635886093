import React from 'react';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { Consumer } from 'store/createContext';
import { motion } from "framer-motion";


const Container = styled(motion.div)`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: 20;
  pointer-events: none;
  transition: height 0.1s;
`;

const BorderTop = styled(motion.div)`
  position: absolute;
  border-top: 20px solid ${ props => props.varAccent };
  border-bottom: 20px solid ${ props => props.varAccent };
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: border-color 1s;

  ${MEDIA.DESKTOP`
    border-top-width: 16px;
    border-bottom-width: 16px;
  `};
  ${MEDIA.TABLET`
  border-top-width: 12px;
  border-bottom-width: 12px;
  `};
`;
const BorderSides = styled(motion.div)`
  position: absolute;
  border-left: 20px solid ${ props => props.varAccent };
  border-right: 20px solid ${ props => props.varAccent };
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: border-color 1s;

  ${MEDIA.DESKTOP`
    border-left-width: 16px;
    border-right-width: 16px;
  `};
  ${MEDIA.TABLET`
    border-left-width: 12px;
    border-right-width: 12px;
  `};
`;
const MaskBottom = styled(motion.div)`
  position: absolute;
  border-bottom: 22px solid white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;


const Border = () => {
  return (
    <Consumer>
    {({ varAccent }) => (
        <Container>
          <BorderTop 
            varAccent = {varAccent}
            initial = {{ scaleX: 0 }}
            animate = {{
              scaleX: 1,
              transition: { 
                duration: 0.3,
              }
            }}
          />
          <MaskBottom 
            initial = {{ scaleX: 1 }}
            animate = {{
              scaleX: 0,
              transition: { 
                duration: 0.3,
                delay: 0.4, 
              },
              transitionEnd: {
                display: "none",
              },
            }}
          />
          <BorderSides 
            varAccent = {varAccent}
            initial = {{ height: 0 }}
            animate = {{
              height : '100%',
              transition: { 
                duration: 0.3,
                delay: 0.2, 
              },
            }}
          />
        </Container>
    )}
    </Consumer>
  )
};

export default Border;